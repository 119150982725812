import React from "react";
import './index.scss'
import Mail from '../../../assets/images/mail.svg'
import Phone from '../../../assets/images/phone.svg'
import Logo from '../../../assets/images/logo.svg'

function Footer() {
    const contacts = [
        {
            icon: Mail,
            title: "info@berezkaevents.ae",
            href: "mailto: info@berezkaevents.ae"
        },
        {
            icon: Phone,
            href: "tel:+971507697494",
            title: "+971 50 769 7494"
        },
    ]

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="footer__content">
                        {/* <div className="footer__socials">
                            {socialLinks.map((social) => {
                                return <a href={social.url} className="footer__social-link">
                                    <img src={social.icon} alt={social.alt} />
                                </a>
                            })}
                        </div> */}

                        <div className="footer__contacts">
                            {/* <p className="footer__copyright mb-2">
                                st. 3rd backbone 20B, Москва, 109012
                            </p> */}
                            {contacts.map((el) => {
                                return <a href={el.href} className="footer__contacts-item">
                                    <div className="footer__contact-item-icon">
                                        <img src={el.icon} alt="" />
                                    </div>
                                    <span>{el.title}</span>
                                </a>
                            })}
                            <p className="footer__copyright">
                                Al Barsha First,
                                Barsha Valley Building,
                                Office 02-07,
                                Makani No 18856 78299,
                                Dubai, UAE
                            </p> <br />
                            <p className="footer__copyright">
                                Ⓒ BEREZKA EVENTS L.L.C, 2022. All rights reserved
                            </p>
                        </div>
                        <img className="footer__logo" src={Logo} alt="" />
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
