import React from 'react';
import Footer from '../layouts/Footer';
import Gallery from '../widgets/Gallery';
import Videos from '../widgets/Videos';
import Promo from '../widgets/Promo';
import MainSlider from '../widgets/MainSlider';

function MainPage(props) {
    return <>
        <Promo />
        <MainSlider />
        <section className="promo" id="promo" style={{background: '#fff'}}>
            <div className="container">
                <div className="promo__content">
                    <p className="promo__text" style={{color: "#252523"}}>
                        We Offer Our clients breathtaking events through creativity & uniqueness based on their tastes & preferences. Whether the event is corporate or social, we incorporates themes, design layouts, event formats, and décor to give an outstanding positive result of making your design-specific event a most elegant, unique, and memorable experience.
                    </p>
                </div>
            </div>
            {/* </SwiperSlide>
                </Swiper> */}
        </section>
        <Gallery slides={["photo6.jpg", "photo1.jpg", "photo3.jpg", "photo2.jpg"]} />
        <Videos slides={["/public/videos/video1.mp4", "/public/videos/video2.mp4"]} />
        <Footer />
    </>
}

export default MainPage