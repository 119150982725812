import React, { useEffect } from "react";
import Router from './jsx/core/router'
import useLocation from "wouter/use-location";

function App() {
  const [location] = useLocation();

  useEffect(() => {
    console.log('wwwasd')
    if (window.location.href.includes("#")) {
      document.getElementById(window.location.href?.split('#')[1]).scrollIntoView()
      return
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  }, [location])
  return (
    <div className="page">
      <Router />
    </div>
  );
}

export default App;
