import React, { useRef, useState } from 'react'
import './index.scss'
import SwiperCore, { Navigation, Pagination } from "swiper/core"
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowLight from '../../../assets/images/arrow-right-light.svg'
import ImageShadow from 'react-image-shadow';
import 'react-image-shadow/assets/index.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function Gallery(props) {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    SwiperCore.use([Navigation, Pagination]);

    const handleClick = (index) => {
        setOpen(true);
        setIndex(index)
    }
    return <>
        <section className="gallery gallery_light">
            <div className="container">
                <h3 className="gallery__title">
                    <span>
                        Events
                </span>
                </h3>

                <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    loop
                    scrollbar={{ draggable: false }}
                    className="gallery__slider"
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        991: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    {props.slides.map((el, i) => <SwiperSlide onClick={() => handleClick(i)} className="gallery__slide">
                        <ImageShadow src={`public/images/gallery/${el}`} width="100%"/>
                    </SwiperSlide>)}

                    <div className="gallery__arrows">
                        <div
                            className="gallery__arrow gallery__arrow_prev"
                            ref={navigationPrevRef}
                        >
                            <img src={ArrowLight} alt="" />
                        </div>
                        <div
                            className="gallery__arrow gallery__arrow_next"
                            ref={navigationNextRef}
                        >
                            <img src={ArrowLight} alt="" />
                        </div>
                    </div>
                </Swiper>
            </div>
        </section>
        {open && (
            <Lightbox
                mainSrc={`public/images/gallery/${props.slides[index]}`}
                nextSrc={`public/images/gallery/${props.slides[(index + 1) % props.slides.length]}`}
                prevSrc={`public/images/gallery/${props.slides[(index + props.slides.length - 1) % props.slides.length]}`}
                onCloseRequest={() => setOpen(false)}
                onMovePrevRequest={() =>
                    setIndex((index + props.slides.length - 1) % props.slides.length)
                }
                onMoveNextRequest={() => {
                    setIndex(((index + 1) % props.slides.length))
                }
                }
            />
        )}
    </>
}

export default Gallery
