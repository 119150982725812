
import React, { useState } from 'react'
import './index.scss'
import SwiperCore, { Navigation, Pagination,  Autoplay } from "swiper/core";
import Logo from '../../../assets/images/logo.svg'
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import CorporateEvents from '../../../assets/images/corporateEvents.webp'
import PrivateEvents from '../../../assets/images/privateEvents.jpeg'
import Weddings from '../../../assets/images/weddings.webp'
import Exhibitions from '../../../assets/images/exhibitions.jpeg'
import Concerts from '../../../assets/images/concerts.jpeg'
import SukachevConcert from '../../../assets/images/sukachev_concert.jpg'

function MainSlider(props) {
    SwiperCore.use([Autoplay, Navigation, Pagination]);

    const [swiper, setSwiper] = useState(null);
    const slideTo = (index) => swiper.slideTo(index);

    const arr = [
        {
            image: SukachevConcert,
            title: "Garik Sukachev",
            text: "We will be waiting for you at this wonderful concert."
        },
        {
            image: CorporateEvents,
            title: "Corporate events",
            text: "We create first class event with you because you deserve nothing less."
        },
        {
            image: PrivateEvents,
            title: "Private events",
            text: "We specialize in organizing events at very high level and at a very short notice."
        },
        {
            image: Weddings,
            title: "Weddings",
            text: "Everything you need to plan we will do for you to save the bestmemories about the most important day of your happy family."
        },
        {
            image: Exhibitions,
            title: "Exhibitions",
            text: "Our conference management is designed to help you get your message across to your audience in the most effective and professional way."
        },
        {
            image: Concerts,
            title: "Concerts",
            text: "We offer a wide range of top quality equipment for creating the unique event"
        }
    ]
    return (
        <section className="mainSlider mainSlider_slider" id="mainSlider">
            <div className="mainSlider__content">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    loop
                    autoplay={{ delay: 5000 }}
                    // scrollbar={{ draggable: false }}
                    // onSwiper={setSwiper}
                    className="mainSlider__slider"
                >
                    {arr.map((el) => {
                        return <SwiperSlide className="mainSlider__block">
                            <div className="mainSlider__slide-wrapper" style={{backgroundImage: `url(${el.image})`}}>
                                {/* <img src={el.image} alt="" className="mainSlider__image" /> */}
                                <div className="mainSlider__heading">{el.title}</div>
                                <p className="mainSlider__text">{el.text}</p>
                            </div>
                        </SwiperSlide>
                    })}

                </Swiper>
            </div>
        </section >
    )
}

export default MainSlider
