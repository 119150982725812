
import React from 'react'
import './index.scss'
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import Logo from '../../../assets/images/logo.svg'
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Promo(props) {
    SwiperCore.use([Navigation, Pagination]);


    return (
        <section className="promo" id="promo">
            <div className="container">
                <div className="promo__content">
                    <img className="promo__image" src={Logo} alt="" />
                </div>
            </div>
            {/* </SwiperSlide>
                </Swiper> */}
        </section>
    )
}

export default Promo
